import React from 'react';
import {
  action,
  runInAction,
  observable,
  computed,
  reaction,
  IReactionDisposer,
} from 'mobx';
import io from 'socket.io-client';
import axios from 'axios';

import { setStorageItem, getJsonStorageItem } from '../utils/storage';
import { getDefaultLanguage } from '../utils/language';
import { QuestionStore } from './question';
import { ResultsStore } from './results';
import { TeamStore } from './team';
import { i18n } from '../configs/i18n';
import { ApiService } from '../services/api';
import { GameData } from '../types/game-data';
import { ErrorData } from '../types/error-data';
import { Config } from '../types/config';
import { QuizSocket } from '../types/socket';
import { AppStates } from '../types/app-states';
import { SignUpData } from '../types/sign-up';
import type { AudioStore } from './audio';
import { updateUrl } from '../utils/update-url';
import { publishError } from '../configs/sentry/sentry-utils';
import { TLayout } from '../types/layout';
import { isDev } from '../constants';
import { getAppConfigFromFile } from '../utils/get-app-config-file';

export class AppStore {
  @observable state = AppStates.Initial;
  @observable fetching = false;
  @observable userName: string | null = null;
  @observable userId: string | null = null;
  @observable gameData: GameData | undefined;
  @observable error: ErrorData = null;
  @observable token: string | null = null;
  @observable locale: string = getDefaultLanguage();
  @observable headerHeight = 0;
  @observable config?: Config;
  @observable hideVideo: boolean;
  @observable isSocketConnected = false;
  @observable isGameEnded = false;
  @observable cdnUrl = '//config.quizheroes.ru';
  @observable isInitialized = false;
  @observable layout: TLayout = 'quizheroes';

  @observable disposers: IReactionDisposer[] = [];

  socket: QuizSocket;
  api: ApiService;
  teamStore: TeamStore;
  questionStore: QuestionStore;
  resultsStore: ResultsStore;
  audioStore: AudioStore | undefined;

  @computed
  get isAuthorized() {
    return this.state === AppStates.SignedIn;
  }

  @computed
  get videoSrc() {
    return this.gameData?.stream;
  }

  @computed
  get automaticVideoConfig() {
    return this.gameData?.automatic_video;
  }

  @computed
  get canChangeCaptain() {
    return Boolean(this.gameData?.can_change_captain);
  }

  @computed
  get isCaptain() {
    return this.teamStore?.isCaptain;
  }

  @computed
  get isSocialsVisible() {
    return this.config?.FOOTER_SOCIALS ?? true;
  }

  @computed
  get isFaqAvailable() {
    return this.config?.FAQ ?? true;
  }

  @computed
  get isGameWithBreak() {
    return this.config?.HAS_BREAK ?? true;
  }

  @computed
  get isAudioChatAvailable() {
    return this.isSocketConnected && (this.config?.AUDIO_CHAT ?? false);
  }

  @computed
  get gameId() {
    return this.gameData?.id;
  }

  @computed
  get telegramSupportLink() {
    return this.config?.TELEGRAM_SUPPORT_LINK;
  }

  @computed
  get prioritizeMyTeam() {
    return this.config?.PRIORITIZE_MY_TEAM ?? false;
  }

  get savedSessionStorageKey() {
    return this.gameId ? `quiz-heroes-last-user-${this.gameId}` : undefined;
  }

  get savedSession() {
    try {
      return this.getSavedSession();
    } catch (error) {
      return null;
    }
  }

  @computed
  get activeTabStorageKey() {
    if (!this.gameData?.user_token) {
      return '';
    }
    return `quizheroes-active-tabs-counter-${this.gameData?.user_token}`;
  }

  constructor(config: {
    token: string | null;
    hideVideo: boolean;
    name: string | null;
  }) {
    const { token, hideVideo, name } = config;
    this.setToken(token);
    if (name) {
      this.setUserName(name);
    }
    this.hideVideo = hideVideo;
    this.teamStore = new TeamStore(this);
  }

  async fetchConfig() {
    const fileName = isDev() ? 'beta' : window.location.host.split('.')[0];
    const { data } = await axios.get<Config>(`${this.cdnUrl}/${fileName}.json`);

    this.setConfig(data);
  }

  async configureAPI() {
    const configFile = getAppConfigFromFile();
    if (configFile.API_URL) {
      this.setConfig(configFile);
    } else {
      await this.fetchConfig();
    }

    if (!this.config?.API_URL) {
      throw new Error('Can not create api service beacuse of empty API_URL');
    }

    if (!this.config?.WEBSOCKET_API_URL) {
      throw new Error(
        'Can not create api service beacuse of empty WEBSOCKET_API_URL'
      );
    }

    this.api = new ApiService(this.config.API_URL);
  }

  init = async () => {
    try {
      await this.configureAPI();

      // TODO: move stores init to widget
      this.resultsStore = new ResultsStore(this, this.config?.RESULTS_LIMIT);
      this.questionStore = new QuestionStore(this);
    } catch (error) {
      publishError(error);
      this.setError({
        reason_key: 'error.config',
      });
      return;
    }
    this.configureSocket();

    if (!this.isInitialized) {
      this.start();
      runInAction(() => {
        this.isInitialized = true;
      });
    }

    this.disposers.push(
      reaction(
        () => this.state,
        state => {
          if (state === AppStates.SignedIn) {
            this.saveSession();
            this.configureSocket();
            this.listenSocket();
            this.increaseActiveWindows();
          }

          if (state === AppStates.Initial) {
            this.configureSocket();
            this.listenSocket();
          }
        }
      )
    );

    this.disposers.push(
      reaction(
        () => this.isCaptain,
        isCaptain => {
          const nextToken = this.getTokenByRole(isCaptain);

          if (nextToken && this.token !== nextToken) {
            this.setToken(nextToken);

            updateUrl(nextToken);
          }
        }
      )
    );

    this.disposers.push(
      reaction(
        () => this.locale,
        locale => {
          if (locale) {
            i18n.changeLanguage(locale);
          }
        }
      )
    );
  };

  getTokenByRole(isCaptain: boolean) {
    return this.gameData?.[isCaptain ? 'captain_link' : 'team_link'].split(
      'token='
    )[1];
  }

  @action
  setToken(token: string | null) {
    this.token = token;
  }

  start = async () => {
    try {
      this.setFetching(true);
      const isChecked = await this.check();

      if (isChecked && this.userName) {
        await this.connect(this.userName, this.savedSession?.userToken);
      }
    } catch (error) {
      this.setError({ reason: error.message });
    } finally {
      this.setFetching(false);
    }
  };

  check = async () => {
    const token = this.token || '';
    const { data } = await this.api.check(token);
    const { status, reason, header, locale, name, id } = data;

    if (locale) {
      this.setLocale(locale);
    }

    this.setGameData({ name, id } as GameData); //TODO: fix me

    if (!token) {
      this.setError({
        reason_key: 'error.token',
      });
      return false;
    }

    if (status === 'success') {
      this.setState(AppStates.Checked);
    } else {
      this.socket.once('gameStarted', this.start);
      this.setGameData({ name: header } as GameData);
      this.setError({ reason, header });
      return false;
    }

    return true;
  };

  @action
  setState(value: AppStates) {
    this.state = value;
  }

  @action
  setCheked() {
    this.state = AppStates.Checked;
  }

  @action
  setFetching(value: boolean) {
    this.fetching = value;
  }

  @action
  setUserName(value: string) {
    this.userName = value;
  }

  @action
  setLocale(value: string) {
    this.locale = value;
  }

  @action
  setError(error?: Partial<ErrorData>) {
    const { header, header_key, reason, reason_key } = error || {};
    this.state = AppStates.Error;

    this.error = {
      header,
      header_key,
      reason,
      reason_key,
    };
  }

  @action.bound
  connect(name: string, userToken?: string) {
    if (!this.token) {
      throw new Error('Token is required.');
    }

    this.setUserName(name);
    this.setFetching(true);
    return this.api
      .connect(this.token, name, userToken)
      .then(response => {
        const { data } = response;

        if (data.status === 'error') {
          this.setError({ header: data.header, reason: data.reason });
          return data.reason;
        }

        this.setGameData(response.data);

        this.trackActiveWindow();
        this.increaseActiveWindows();

        this.setState(AppStates.SignedIn);

        this.teamStore.setScore(
          this.gameData?.team_stat || {
            score: 0,
            rounds: [],
          }
        );

        return response.data;
      })
      .catch(error => {
        publishError(error);
        this.setError({ reason_key: 'error.server' });
      })
      .finally(() => {
        this.setFetching(false);
      });
  }

  configureSocket() {
    this.socket?.close();

    if (!this.config) {
      throw new Error('Config is empty');
    }

    this.socket = io(this.config.WEBSOCKET_API_URL, {
      query: {
        token: this.token,
        team_room: String(this.gameData?.team_room),
        user_token: String(this.gameData?.user_token),
      },
    });
  }

  listenSocket() {
    this.socket.on('connect', () => {
      this.setIsSocketConnected(true);
    });
    this.socket.on('disconnect', () => {
      this.setIsSocketConnected(false);
    });
    this.socket.on('gameEnded', () => {
      this.setIsGameEnded(true);
    });
  }

  cleanUp() {
    this.socket?.close();
    this.disposers.forEach(disposer => disposer());
    this.stopTrackActiveWindow();
  }

  getSavedSession() {
    if (!this.savedSessionStorageKey) {
      throw new Error('Cannot get saved session because of empty storage key.');
    }

    return getJsonStorageItem<{ userName: string; userToken: string }>(
      this.savedSessionStorageKey
    );
  }

  saveSession() {
    if (!this.savedSessionStorageKey) {
      throw new Error('Cannot save session because of empty storage key.');
    }

    setStorageItem(
      this.savedSessionStorageKey,
      JSON.stringify({
        userName: this.userName || '',
        userToken: this.gameData?.user_token || '',
      })
    );
  }

  restoreSession = () => {
    const savedSession = this.getSavedSession();
    if (!savedSession) {
      throw new Error('Cannot restore session because of empty saved session.');
    }
    if (!savedSession.userName || !savedSession.userToken) {
      throw new Error(
        'Cannot restore session because of empty userName or userToken.'
      );
    }
    this.connect(savedSession.userName, savedSession.userToken);
  };

  // TODO: move to team or stay here?
  // captain
  makeCaptain = (userToken: string) => {
    if (!this.token) {
      throw new Error('Token is required.');
    }

    this.setFetching(true);
    this.api
      .makeCaptain(this.token, userToken)
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.setFetching(false);
      });
  };

  signUp = async (value: SignUpData): Promise<string> => {
    try {
      const { data } = await this.api.signUp(value);
      if (data.status === 'error') {
        throw new Error(data.reason);
      }
      return data.url;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  public endGame = () => {
    this.stopTrackActiveWindow();
    this.cleanGameData();
    this.start();
  };

  trackActiveWindow = () => {
    window.addEventListener('storage', this.handleActiveTabs);
  };

  stopTrackActiveWindow = () => {
    window.removeEventListener('storage', this.handleActiveTabs);
  };

  handleActiveTabs = (event: StorageEvent) => {
    if (
      event.key === this.activeTabStorageKey &&
      parseInt(event.newValue || '') >= 2
    ) {
      this.endGame();
      localStorage.setItem(this.activeTabStorageKey, '1');
    }
  };

  increaseActiveWindows() {
    const count = Number(localStorage.getItem(this.activeTabStorageKey) || 0);
    const nextCount = count + 1;
    localStorage.setItem(this.activeTabStorageKey, String(nextCount));
  }

  @action
  cleanGameData() {
    this.userId = null;
    this.userName = null;
    this.gameData = undefined;
    this.state = AppStates.Initial;
    this.isGameEnded = false;
  }

  @action.bound
  setHeaderHeight(value: number) {
    this.headerHeight = value;
  }

  @action
  setConfig = (value: Config) => {
    this.config = value;
  };

  @action
  setGameData = (value?: GameData) => {
    this.gameData = value;
  };

  @action
  setIsGameEnded = (value: boolean) => {
    this.isGameEnded = value;
  };

  @action
  setIsSocketConnected = (value: boolean) => {
    this.isSocketConnected = value;
  };

  @action
  setAudioStore = (store: AudioStore) => {
    this.audioStore = store;
  };
}

export const AppStoreContext = React.createContext<AppStore | null>(null);

export const useAppStore = () => {
  const appStore = React.useContext(AppStoreContext);

  if (!appStore) {
    throw new Error(
      'useAppStore must be used within a AppStoreContext.Provider.'
    );
  }

  return appStore;
};
