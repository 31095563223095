import React, { FC, PropsWithChildren } from 'react';
import cn from 'classnames';

import classes from './card.module.css';

type Props = {
  className?: string;
} & PropsWithChildren;

export const Card: FC<Props> = ({ children, className }) => {
  return <div className={cn(classes.card, className)}>{children}</div>;
};
