import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Row, Col } from 'react-bootstrap';

import styles from './error.module.css';
import { Card } from '../../ui/card/card';

const colProps = {
  xs: 12,
  sm: { span: 10, offset: 1 },
  md: { span: 8, offset: 2 },
};

type Props = {
  title?: string;
  message?: string;
  errorMessage?: ReactNode;
};

export const ErrorBlock: FC<Props> = ({ message, title, errorMessage }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row>
        <Col {...colProps}>
          <Card>
            <h2>{title || t('error.title')}</h2>
            <p>{message || t('error.unknown')}</p>
            {errorMessage && (
              <div className={styles.errorMessage}>
                <div className={styles.errorMessageBody}>{errorMessage}</div>
              </div>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
